import React from "react"
import Layout from "../../components/Layout";
import Footer from '../../components/Footer';
import Wergo from "../../modules/clients/Wergo";

const WirlPage = () => {

  return (
    <Layout>
      <Wergo />
      <Footer />
    </Layout>
  )
}

export default WirlPage;